.main-footer {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: #222222;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  color: white;
  justify-content: space-between;
  z-index: 5;
}

.footer-left {
  display: flex;
  align-items: center;
}

.footer-right {
  display: flex;
  align-items: center;
}

.feedback-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  padding: 0;
}

.feedback-button:hover {
  color: #ccc;
}

.privacy-policy-link {
  margin-left: 15px;
  color: inherit;
  text-decoration: none;
  font-size: 0.9rem;
}

.privacy-policy-link:hover {
  text-decoration: underline;
}

.legal-link {
  margin-left: 15px;
  color: inherit;
  text-decoration: none;
  font-size: 0.9rem;
  transition: opacity 0.2s;
}

.legal-link:hover {
  opacity: 0.8;
  text-decoration: underline;
}

main {
  margin-bottom: 5rem;
}
